import styled, { css } from 'styled-components';
import { MOBILE_WIDTH, PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION, PLAYER_HEIGHT } from '../../model/page';
import { Typography, gridSize } from '../../styles';

export const SLIDING_MODAL_ANIMATION_TIME_IN_MS = 200;

export const SlidingModalPlacement = styled.div<{ $isOpen: boolean }>`
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - ${PLAYER_HEIGHT}px);
    overflow: hidden;
    display: flex;

    visibility: hidden;
    transition: visibility ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms;

    ${props =>
        props.$isOpen &&
        css`
            visibility: visible;
            ${SlidingModalWrapper} {
                transform: translateY(0);
            }
        `}

    &.sorting {
        cursor: grabbing;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        z-index: 300;
    }
`;

export const SlidingModalWrapper = styled.div`
    width: 100%;
    height: 100%;

    background: ${props => props.theme.tokens['primary-ui-04']};

    transform: translateY(100%);

    @media (prefers-reduced-motion: no-preference) {
        transition: transform ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms
            cubic-bezier(0.25, 0.09, 0.3, 1);
    }

    display: flex;
    flex-direction: column;

    padding-left: ${10 * gridSize}px;
    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION + 1}px) {
        padding-left: ${30 * gridSize}px;
    }

    h1 {
        margin: 0;
        padding: 14px 80px 10px 16px;
        font-size: 20.74px;
        font-weight: 600;
        color: ${props => props.theme.legacyScss['theme-text-primary']};
        letter-spacing: -0.29px;
    }
    .clear-slide-up-modal {
        position: absolute;
        top: 12px;
        right: 15px;

        font-family: 500;
        font-size: 14.4px;
        font-weight: 400;
        cursor: pointer;
        line-height: 22px;
        user-select: none;

        color: #03a9f4;

        &:hover {
            color: #50d0f1;
        }

        transition: all 0.1s ease;
    }
    .error-message {
        padding-top: 170px;
    }
`;

export const SlidingModalContent = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 24px 60px 0 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const SlidingModalHeaderWrapper = styled.div`
    display: flex;
    padding-right: 16px;
`;

export const SlidingModalHeader = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 17px;
    border-bottom: 1px solid ${props => props.theme.tokens['player-contrast-05']};
    h1 {
        ${Typography.Desktop.H10};
        color: ${props => props.theme.tokens['primary-text-01']};
    }
    button {
        color: ${props => props.theme.tokens['player-contrast-02']};
        &:hover {
            color: ${props => props.theme.tokens['player-contrast-01']};
        }
    }
`;

export const SlidingModalBody = styled.div`
    flex: 1;
    position: relative;
    width: calc(100% + 60px);
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

export const SlidingModalTableActions = styled.div`
    color: ${props => props.theme.tokens['contrast-03']};
    ${Typography.Desktop.H70};
    font-weight: 500;
    width: 100%;
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid ${props => props.theme.tokens['player-contrast-05']};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .centered-toolbar & {
        align-items: center;
    }

    button {
        font-weight: 400;
        margin-left: auto;
        color: ${props => props.theme.tokens['primary-interactive-01']};
        &:hover {
            color: ${props => props.theme.tokens['primary-interactive-01-hover']};
        }
    }
`;

export const SlidingModalEmptyMessage = styled.div`
    text-align: center;
    ${Typography.Desktop.H60};
    color: #686c74;
    width: 480px;
    padding: 70px;
    background: ${props => props.theme.tokens['primary-ui-01']};
    border: 2px solid ${props => props.theme.tokens['primary-ui-05']};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        color: ${props => props.theme.tokens['primary-text-01']};
        ${Typography.Desktop.H30};
        padding: 20px 0;
    }
    p {
        margin: 0;
    }
    svg {
        color: ${props => props.theme.tokens['primary-icon-01']};
    }
`;
