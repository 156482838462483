import { SubscribeButton } from 'components/buttons/SubscribeButton';
import styled, { createGlobalStyle } from 'styled-components';
import { A11Y, Typography } from '../../../styles';

export const DiscoverCarouselWrapper = styled.section`
    height: 310px;
    overflow: visible;
`;

export const CardImageWrapper = styled.div`
    padding-left: 0;
    margin-right: 30px;
    width: 218px;
    flex-shrink: 0;
`;

export const CardSubscribeButton = styled(SubscribeButton)`
    opacity: 0;
    position: absolute;
    right: 16px;
    top: 0;
    transition: opacity 0.3s;
    box-shadow: none;
    &.sub5cribed {
        opacity: 1;
    }
`;

export const CardText = styled.div`
    min-height: 220px;
`;

export const CardPodcastTitle = styled.p`
    ${Typography.Desktop.H30};
    margin-bottom: 11px;
    letter-spacing: -0.8px;
`;

export const CardFeaturedBadge = styled.p<{ $isSponsored?: boolean }>`
    color: ${props => props.theme.tokens[props.$isSponsored ? 'primary-text-02' : 'support-01']};
    ${Typography.Desktop['H70-TBC']}
    margin-bottom: 6px;
`;

export const CardAuthor = styled.p<{ $long: boolean }>`
    ${Typography.Desktop['H70-B']};
    color: ${props => props.theme.tokens['primary-text-01']};
    margin-bottom: 10px;
    max-lines: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${props =>
        props.$long &&
        `
        font-size: 14px;
        line-height: 18px;
    }`}
`;
export const CardDescription = styled.p`
    ${Typography.Desktop.H70}
    min-height: 118px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
`;

export const DiscoverCarouselCardWrapper = styled.div`
    height: 248px;
    padding: 0 4px;
    display: inline-block;
    margin-bottom: 30px;
    min-width: 100%;
`;

export const Card = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-flex;
    height: 270px;
    padding: 0 16px 16px 0;
    min-width: 100%;
    overflow: hidden;
    &:hover {
        ${CardSubscribeButton} {
            opacity: 1;
        }
    }
`;

export const SlickGlobalStyle = createGlobalStyle`
.slick-dots {
    li {
        width: 38px;
        height: 38px;
        margin: 0 5px;
        button::before {
            color: transparent;
            z-index: -100;
        }
        button {
            width: 48px;
            height: 48px;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
            &:focus {
                transform: scale(1.2);
            }
            &:focus-visible {
                ${A11Y.outlineInset}
            }
        }
        
        &.slick-active {
            button::before {
                color: transparent;
                z-index: -100;
            }
            button {
                transform: scale(1.2);
            }
        }
    }
}
.slick-next:before,
.slick-prev:before {
    color: ${props => props.theme.tokens['primary-icon-02']};
}
.slick-arrow {
    color: ${props => props.theme.tokens['primary-icon-02']};
}
`;
