import styled from 'styled-components';
import { Typography, blueTextLinkMixin, gridSize } from 'styles';

export const Wrapper = styled.div`
    position: relative;
    margin-left: -8px;
    margin-right: -20px;
`;

export const IconButton = styled.button`
    color: white;

    &:hover svg * {
        fill-opacity: 1;
    }
`;

export const Popover = styled.div`
    position: absolute;
    z-index: 100;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -8px);
    width: 460px;

    background: ${props => props.theme.legacyScss['theme-popup-background']};
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    padding: ${3 * gridSize}px;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        border-style: solid;
        border-width: 7.5px 4.5px 0 4.5px;
        border-color: ${props => props.theme.legacyScss['theme-popup-background']} transparent
            transparent transparent;
    }
`;

export const RecentBookmarks = styled.section`
    margin-bottom: ${2 * gridSize}px;
`;

export const RecentBookmarksHeading = styled.div`
    ${Typography.Desktop['H70-B']}
    padding-bottom: 2px;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    display: flex;
    justify-content: space-between;
    h1 {
        ${Typography.Desktop['H70-B']}
        text-transform: uppercase;
        color: ${props => props.theme.tokens['primary-text-02']};
    }
    button {
        ${blueTextLinkMixin}
    }
`;
