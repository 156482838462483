import { getContentType } from 'helper/EpisodeHelper';
import { withRouter } from 'pages/PodcastPage/PodcastPage';
import { connect } from 'react-redux';
import * as Browser from '../../../helper/Browser';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromFlagsActions from '../../../redux/actions/flags.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import * as fromPodcastActions from '../../../redux/actions/podcast.actions';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import * as fromStatsActions from '../../../redux/actions/stats.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import * as fromUpNextActions from '../../../redux/actions/up-next.actions';
import {
    getChaptersForPlayerEpisode,
    getCurrentPlayingChapterPosition,
    getPlayerChapterIndex,
    getPlayerPlaybackEffects,
    getSettings,
    getSpeedForPodcast,
} from '../../../redux/reducers/selectors';
import { getAutoplayConfig } from '../../../redux/reducers/selectors/autoplay.selectors';
import { userIsLoggedIn } from '../../../redux/reducers/selectors/user.selectors';
import PlayerControls from './PlayerControls';

const mapStateToProps = state => {
    const { episode } = state.player;
    const podcastUuid = episode ? episode.podcastUuid : null;
    const podcastColors = state.podcasts.uuidToColors[podcastUuid];
    const colorTint = podcastColors ? podcastColors.darkTintUnsafe : '#78A8A8';
    const colorBackground = podcastColors ? podcastColors.background : '#78A8A8';

    return {
        podcast: state.podcasts.uuidToPodcast[podcastUuid],
        episode: state.player.episode,
        unplayableFile: state.player.unplayableFile,
        playing: state.player.isPlaying,
        chapterIndex: getPlayerChapterIndex(state),
        chapters: getChaptersForPlayerEpisode(state),
        deselectedChapters: state.player.deselectedChapters,
        getCurrentPlayingChapterPosition: position =>
            getCurrentPlayingChapterPosition(state, position),
        lastSeekTo: state.player.lastSeekTo,
        lastSentPlayedUpTo: state.player.lastSentPlayedUpTo,
        lastSentPlayingStatus: state.player.lastSentPlayingStatus,
        speed: getSpeedForPodcast(state),
        playbackEffects: getPlayerPlaybackEffects(state),
        theme: state.settings.theme,
        muted: state.player.muted,
        errorMessage: state.player.errorMessage,
        volume: state.player.volume,
        upNext: state.upNext,
        settings: state.settings,
        episodeOpen: !!state.episode,
        uuidToPodcast: state.podcasts.uuidToPodcast,
        buffering: state.player.buffering,
        webp: state.settings.webpSupported,
        skipForward: state.settings.skipForward,
        skipBack: state.settings.skipBack,
        colorTint,
        colorBackground,
        videoMode: state.player.videoMode,
        videoWidth: state.player.videoWidth,
        videoHeight: state.player.videoHeight,
        isMacApp: Browser.isMacApp(),
        isElectronApp: Browser.isElectronApp(),
        performanceModeOn: getSettings(state)?.performanceModeOn,
        recommendedEpisodes: state.player.recommendedEpisodes,
        recommendedEpisodesAreLoading: state.player.loadingRecommendedEpisodes,
        autoplayConfig: getAutoplayConfig(state),
        isLoggedIn: userIsLoggedIn(state),
    };
};

const mapDispatchToProps = dispatch => ({
    downloadRecommendations: () => dispatch(fromPlayerActions.Actions.downloadRecommendations()),
    playEpisode: (episodeUuid, podcastUuid, eventSource, autoplay) =>
        dispatch(
            fromPlayerActions.Actions.playEpisode(
                episodeUuid,
                podcastUuid,
                {
                    eventSource,
                },
                { autoplay },
            ),
        ),
    openEpisode: (episode, eventSource, autoplayConfig) =>
        dispatch(fromEpisodeActions.Actions.openEpisode(episode, { eventSource, autoplayConfig })),
    closeEpisode: () => dispatch(fromEpisodeActions.Actions.closeEpisode()),
    pause: eventSource => dispatch(fromPlayerActions.Actions.pause({ eventSource })),
    updatePlayedUpTo: (episodeUuid, playedUpTo) =>
        dispatch(fromPlayerActions.Actions.updatePlayedUpTo(episodeUuid, playedUpTo)),
    updateDuration: (episode, duration) =>
        dispatch(
            fromPlayerActions.Actions.updateDuration(episode.uuid, episode.podcastUuid, duration),
        ),
    episodeFinished: (episode, podcastUuid) =>
        dispatch(fromPlayerActions.Actions.episodeFinished(episode, podcastUuid)),
    playerFailed: errorMessage => {
        dispatch(fromFlagsActions.Actions.addFlag(errorMessage));
        dispatch(fromPlayerActions.Actions.playerFailed(errorMessage));
    },
    saveProgress: (episodeUuid, podcastUuid, playedUpTo) =>
        dispatch(fromPlayerActions.Actions.saveProgress(episodeUuid, podcastUuid, playedUpTo)),
    addTotalListeningTime: timeMs =>
        dispatch(fromStatsActions.Actions.addTotalListeningTime(timeMs)),
    addTimeSavedSkipping: timeMs => dispatch(fromStatsActions.Actions.addTimeSavedSkipping(timeMs)),
    addTimeSavedVariableSpeed: timeMs =>
        dispatch(fromStatsActions.Actions.addTimeSavedVariableSpeed(timeMs)),

    updateSpeed: (podcastUuid, speed, playbackEffects) => {
        dispatch(fromPlayerActions.Actions.updateSpeed(speed));
        if (playbackEffects) {
            dispatch(fromPodcastActions.Actions.updatePlaybackSpeed(podcastUuid, speed));
        } else {
            dispatch(fromSettingsActions.Actions.savePlaybackSpeed(speed));
        }
    },

    updateChapters: chapters => dispatch(fromPlayerActions.Actions.updateChapters(chapters)),
    seekTo: timeSecs => dispatch(fromPlayerActions.Actions.seekTo(timeSecs)),
    onSkipBackClicked: ({ eventSource, episode }) =>
        dispatch(
            fromTracksActions.Actions.recordEvent('playback_skip_back', {
                source: eventSource,
                content_type: getContentType(episode),
            }),
        ),
    onSkipForwardClicked: ({ eventSource, episode }) =>
        dispatch(
            fromTracksActions.Actions.recordEvent('playback_skip_forward', {
                source: eventSource,
                content_type: getContentType(episode),
            }),
        ),
    updateMuted: muted => dispatch(fromPlayerActions.Actions.updateMuted(muted)),
    updateVolume: volume => dispatch(fromPlayerActions.Actions.updateVolume(volume)),
    updateBuffering: buffering => dispatch(fromPlayerActions.Actions.updateBuffering(buffering)),
    updateVideoMode: mode => dispatch(fromPlayerActions.Actions.updateVideoMode(mode)),
    updateVideoSize: (width, height) =>
        dispatch(fromPlayerActions.Actions.updateVideoSize(width, height)),
    openUpNext: () => dispatch(fromUpNextActions.Actions.openUpNext()),
    closeUpNext: () => dispatch(fromUpNextActions.Actions.closeUpNext()),
    recordEvent: (event, properties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
});

const PlayerControlsConnected = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PlayerControls),
);

export { PlayerControlsConnected as PlayerControls };
