import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useSelector } from '../hooks/react-redux-typed';
import useFormatMessage from '../hooks/useFormatMessage';
import useTracks from '../hooks/useTracks';
import { hasPaidSubscription } from '../redux/reducers/selectors';
import { userIsLoggedIn } from '../redux/reducers/selectors/user.selectors';
import { gridSize, Typography } from '../styles';
import urls from '../urls';
import { Button } from './Button';
import { dialogButtonMixin } from './Button/Button.styled';
import { ButtonLink } from './ButtonLink';
import { PlanBadge } from './PlanBadge';
import Popover from './Popover';
import { SubscribeToPlanButtonLink } from './SubscribeToPlanButtonLink';
import { TrackOnMount, TrackOnUnmount } from './Tracks';

// Note: All the paddings below are a bit arbitrary, but these values are necessary to implement
// the required design while at the same time accounting for line heights and font sizes.
// See https://a8c.slack.com/archives/C05RR9P9RAT/p1736343868738509

const Title = styled.h2`
    ${Typography.Desktop.H30};
    padding: 2px 0;
`;

const Description = styled.p`
    ${Typography.Desktop.H70};
    padding: 10px 0;
`;

const Container = styled.div`
    padding: ${3 * gridSize}px ${4 * gridSize}px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FeatureLockMessageContainer = styled.div`
    max-width: 600px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: ${2 * gridSize}px;
    padding: 8px 0;
`;

const PopoverButton = styled(Button)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
`;

const PopoverButtonLink = styled(ButtonLink)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
`;

const SubscribeButtonLink = styled(SubscribeToPlanButtonLink)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
    min-width: 168px;
`;

const PlanBadgeWrapper = styled.div`
    padding-top: 8px;
    padding-bottom: 16px;
`;

/*
This element cannot be a real 'button' element (and instead we set a role="button" and manually
handle many of the behaviors) because in most situations it will be wrapping an actual button,
and having nested buttons is invalid HTML.
*/
export const FeatureLockTrigger = styled.div`
    display: inherit;
    &:hover,
    &:focus-visible {
        cursor: pointer;
    }
    text-transform: inherit;
`;

/**
 * Prevents click events from propagating to parent elements.
 * We need this to prevent the Popover content from activating "locked" features when wrapping them.
 */
const PreventClickPropagation = ({ children }: PropsWithChildren) => {
    const handleClick = (event: React.MouseEvent) => event.stopPropagation();

    return <span onClick={handleClick}>{children}</span>;
};

const addInertAttribute = (child: React.ReactNode) => {
    if (child === undefined || child === null) {
        return null;
    }

    if (typeof child === 'string' || typeof child === 'number' || typeof child === 'boolean') {
        return <span {...{ inert: 'true' }}>{child}</span>;
    }

    if (React.isValidElement(child)) {
        return React.cloneElement(child, {
            ...{ inert: 'true' },
        });
    }
    return child;
};

const InertChildren = ({ children }: PropsWithChildren) => {
    if (!children) {
        return children;
    }

    const inertChildren = React.Children.map(children, child => addInertAttribute(child));

    return <>{inertChildren}</>;
};

type FeatureLockMessageProps = {
    requires?: 'account' | 'subscription';
    title?: React.ReactNode;
    description?: React.ReactNode;
    redirect?: string;
    subscribeClick?: () => void;
    createAccountClick?: () => void;
    extraActions?: React.ReactNode;
    image?: React.ReactNode;
};

export const FeatureLockMessage: FC<PropsWithChildren<FeatureLockMessageProps>> = ({
    title,
    description,
    redirect,
    createAccountClick,
    subscribeClick,
    extraActions,
    requires,
    image,
}) => {
    const formatMessage = useFormatMessage();
    const requiresAccount = requires === 'account';
    const requiresSubscription = requires === 'subscription';

    return (
        <Container>
            <MainWrapper>
                {requiresSubscription && (
                    <PlanBadgeWrapper>
                        <PlanBadge tier="Plus" />
                    </PlanBadgeWrapper>
                )}

                <Title>{title || formatMessage('feature-generic-account-unavailable-title')}</Title>
                <Description>
                    {description ||
                        formatMessage('feature-generic-account-unavailable-description')}
                </Description>
                <ButtonsWrapper>
                    {requiresAccount && (
                        <PopoverButtonLink
                            kind="primary"
                            to={urls.registerPath({ redirect })}
                            onClick={createAccountClick}
                        >
                            <FormattedMessage id="create-account" />
                        </PopoverButtonLink>
                    )}
                    {requiresSubscription && (
                        <SubscribeButtonLink
                            tier="Plus"
                            to={urls.subscribePath({ redirect, variant: 'plus' })}
                            onClick={subscribeClick}
                        >
                            <FormattedMessage id="try-plus" />
                        </SubscribeButtonLink>
                    )}
                    {extraActions}
                </ButtonsWrapper>
            </MainWrapper>
            {image && <ImageWrapper>{image}</ImageWrapper>}
        </Container>
    );
};

/**
 * A wrapper component that locks features behind account or subscription requirements.
 *
 * Note: In order for this component to work properly, children must forward the 'inert'
 * attribute to their resulting top level DOM element.
 */
type Props = React.PropsWithChildren<{
    requires?: 'account' | 'subscription';
    className?: string;
    style?: React.CSSProperties;
    title?: React.ReactNode;
    description?: React.ReactNode;
    redirect?: string;
    source?: string;
    enabled?: boolean;
}>;

const FeatureLock = ({
    children,
    requires = 'account',
    title = '',
    description = '',
    redirect,
    className,
    style,
    source = 'unknown',
    enabled = true,
}: Props) => {
    const hasAccount = useSelector(userIsLoggedIn);
    const hasSubscription = useSelector(hasPaidSubscription);
    const requiresAccount = requires === 'account';
    const requiresSubscription = requires === 'subscription';
    const { recordEvent, setDefaultEventSource, clearDefaultEventSource } = useTracks();
    const [isOpen, setIsOpen] = React.useState(false);
    const location = useLocation();

    const eventProperties = { source };

    const handleOnClose = () => {
        clearDefaultEventSource();
        recordEvent('free_user_popup_not_now_tapped', eventProperties);
        setIsOpen(false);
    };

    const subscribeClick = () => {
        setDefaultEventSource(source);
        recordEvent('free_user_popup_subscribe_tapped', eventProperties);
    };

    const createAccountClick = () => {
        setDefaultEventSource(source);
        recordEvent('free_user_popup_create_account_tapped', eventProperties);
    };

    if (requiresAccount && hasAccount) {
        return children;
    }

    if (requiresSubscription && hasSubscription) {
        return children;
    }

    if (!enabled) {
        return children;
    }

    return (
        <Popover
            onClose={handleOnClose}
            isOpen={isOpen}
            trigger={
                <FeatureLockTrigger
                    role="button"
                    tabIndex={0}
                    onClick={event => {
                        event.stopPropagation();
                        setIsOpen(true);
                    }}
                    onKeyDown={event => {
                        if (event.key === 'Enter') {
                            setIsOpen(true);
                        }
                    }}
                    className={className}
                    style={style}
                >
                    <InertChildren>{children}</InertChildren>
                </FeatureLockTrigger>
            }
        >
            <TrackOnMount event="free_user_popup_shown" properties={eventProperties} />
            <TrackOnUnmount event="free_user_popup_dismissed" properties={eventProperties} />
            <PreventClickPropagation>
                <FeatureLockMessageContainer>
                    <FeatureLockMessage
                        requires={requires}
                        title={title}
                        description={description}
                        redirect={redirect ?? location.pathname}
                        subscribeClick={subscribeClick}
                        createAccountClick={createAccountClick}
                        extraActions={
                            <Popover.Close asChild>
                                <PopoverButton
                                    type="button"
                                    kind="secondary"
                                    isPlus={requiresSubscription}
                                    onClick={handleOnClose}
                                >
                                    <FormattedMessage id="not-now" />
                                </PopoverButton>
                            </Popover.Close>
                        }
                    />
                </FeatureLockMessageContainer>
            </PreventClickPropagation>
        </Popover>
    );
};

export default FeatureLock;
